body{
    .iswarning {
            div {
                background-color: #202123;
                color: white;
                border-radius: 8px;
    
                >span {
                    font-size: 16px;
                    font-weight: 400;
    
                    svg {
                        display: none;
                    }
                }
            }
        }
        .modal {
            width: 560px;
            div{
                border: none;
                width: 560px;
                input{
                    width: 512px;
                    height: 40px;
                    outline: none;
                    border: 1px solid #C9CCCF;
                    border-radius: 4px;
                    padding: 9px 12px;
                }
                button{
                    width: 80px;
                    height: 40px;
                    margin-left: 16px;
                    border-radius: 4px;
                    cursor: pointer;
                }
                .cancel{
                    border: 1px solid #CED0D6;
                    background-color: #fff;
                }
                .cancel:hover{
                    background: #F6F6F7;
                }
                .cancel:active{
                    background: #F1F2F3;
                }
                .next{
                    background: #2C6ECB;
                    border: none;
                    color: #fff;    
                }
                .next:hover{
                    background: #1F5199;
                }
                .next:active{
                    background: #103262;
                }
                .nextno{
                    border: none;
                    background: #BDC1CC;
                    color: #fff;
                }
            }
        }
}
.box{
    min-width: 1240px;
    .header{
        max-width: 1224px;
        margin: auto;
        margin-top: 32px;
        margin-bottom: 24px;
        p{
            margin-bottom: 8px;
            font-weight: 700;
            font-size: 24px;
            color: #242629;
            line-height: 32px;
        }
        span{
            color: #929398;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
        }
    }
    form{
        max-width: 1224px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        .left{
            width: 808px;
            height: 100%;
            background: #FFFFFF;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15);
            border-radius: 8px;
            padding: 24px;
            display: flex;
            flex-direction: column;
            label{
                font-size: 18px;
                font-weight: 500;
                color: #000000;
                margin-bottom: 12px;
                margin-top: 40px;
            }
            label::after{
                content: "*";
                color: red;
            }
            .file{
                width: 760px;
                height: 216px;
                background: #FFFFFF;
                border: 1px solid #C9CCCF;
                border-radius: 4px;
                >div{
                    padding: 0 24px;
                }
                .upload{
                    width: 712px;
                    height: 120px;
                    border-radius: 4px;
                    outline: none;
                    cursor: pointer;
                    opacity: 0;
                    position: absolute;
                    margin-top: 10px;
                }
                .uploaddiv{
                    margin-top: 10px;
                    width: 712px;
                    height: 120px;
                    background: #F4F5F7;
                    border: 1px dashed #CED0D6;
                    border-radius: 4px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    p{
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 26px;
                        margin: 0;
                        color: #242629;
                        margin-bottom: 8px;
                        display: flex;
                        align-items: center;
                        i{
                            margin-right: 8px;
                        }
                    }
                    span{
                        font-size: 14px;
                        color: #929398;
                        font-weight: 400;
                        line-height: 22px;
                    }
                }
                
            }
            .fileyes{
                width: 760px;
                height: 0;
                overflow: hidden;  
                
            }
            .requestinfono{
                width: 760px;
                height: 0;
                overflow: hidden;
            }
            .requestinfo{
                width: 760px;
                height: 100%;
                margin-bottom: 10px;
                .requesttop{
                    height: 76px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #C9CCCF;
                    padding-bottom: 16px;
                    div{
                        display: flex;
                        align-items: center;
                        span{
                            margin-left: 16px;
                            max-height: 60px;
                            max-width: 433px;
                            overflow: hidden;
                            display: flex;
                            flex-wrap: wrap;
                            word-break: break-all;
                        }
                        img{
                            width: 40px;
                            height: 48px;
                        }
                    }
                    i{
                        width: 60px;
                        height: 60px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    i::before{
                        font-size: 75px;
                        color: #8C9196;
                    }
                }
                .requestbuttom{
                    display: flex;
                    flex-direction: column;
                    .requestwarn{
                        width: 760px;
                        height: 84px;
                        background: #EBF9FC;
                        border-radius: 8px;
                        border: 1px solid #98C6CD;
                        padding: 20px;
                        display: flex;
                        align-items: flex-start;
                        span{
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                    div{
                        height: 54px;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #C9CCCF;
                        position: relative;
                        div{
                            width: 18px;
                            height: 18px;
                            margin: 0;
                            position: absolute;
                            cursor: pointer;
                            border-radius: 4px;
                            border: 2px solid #8C9196;
                        }
                        div:hover{
                            border: 2px solid #2C6ECB;
                        }
                        input{
                            width: 18px;
                            height: 18px;
                            border: none;
                            position: absolute;
                            border: 2px solid #8C9196;
                            border-radius: 4px;
                            opacity: 0;
                            cursor: pointer;
                        }
                        span{
                            margin-left: 28px;
                        }
                        i{
                            width: 18px;
                            height: 18px;
                            position: absolute;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        i::before{
                            font-size: 23px;
                            color: #2C6ECB;
                        }
                    }
                }
            }

            .select{
                display: flex;
                align-items: center;
                justify-content: space-between;
                >div{
                    input {
                        width: 364px;
                        height: 40px;
                        background: #FFFFFF;
                        outline: none;
                        border: 1px solid #C9CCCF;
                        border-radius: 4px;
                        padding: 9px 12px;
                        background: url(../images/arrow.png) no-repeat;
                        background-position: 98%;
                        cursor: pointer;
                        font-weight: 400;
                        font-size: 14px;
                    }
                    ul{
                        display: none;
                        width: 364px;
                        height: 96px;
                        position: absolute;
                        background: #FFFFFF;
                        box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.1), 0px 6px 16px rgba(0, 0, 0, 0.06), 0px 9px 28px 8px rgba(0, 0, 0, 0.03);
                        border-radius: 4px;
                        padding: 8px 0;
                        li{
                            width: 364px;
                            height: 40px;
                            text-align: left;
                            padding: 9px 12px;
                            font-size: 14px;
                            font-weight: 400;
                            color: #262938;
                            cursor: pointer;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                    }
                }
                >div:hover{
                    ul{
                        display: block;
                    }
                }
                input:hover{
                    border: 1px solid #2C6ECB;
                }
                i{
                    width: 16px;
                    height: 16px;
                    display: flex;
                    align-items: center;
                }
                i::before{
                    font-size: 16px;
                }
            }
            .radio{
                height: 332px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                >div{
                    width: 760px;
                    height: 74px;
                    border: 1px solid #C9CCCF;  
                    border-radius: 4px;
                    display: flex;
                    margin-bottom: 12px;
                    align-items: center;
                    input{
                        border: 1px solid #2C6ECB;
                        width: 760px;
                        height: 74px;
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                    }
                    
                    li{
                        width: 17px;
                        height: 17px;
                        border: 1px solid #8C9196;
                        border-radius: 100px;
                        margin-left: 17px;
                    }
                    i{
                        
                        width: 20px;
                        height: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 16px;
                    }
                    i::before{
                        
                        color: #2C6ECB;
                        font-size: 20px;
                    }
                    
                    div{
                        height: 42px;
                        margin-left: 12px;
                        p{
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 24px;
                            margin: 0;
                        }
                        span{
                            font-size: 12px;
                            font-weight: 400;
                            color: #6D7175;
                            line-height: 18px;
                        }
                    }
                }
                .check{
                    border: 2px solid #2C6ECB;
                }
            }
            .info{
                margin-top: 40px;
                div{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 4px;
                    margin-top: 12px;
                    div{
                        display: flex;
                        flex-direction: column;
                        width: 368px;
                        height: 70px;
                        justify-content: space-between;
                        label{
                            font-size: 14px;
                            font-weight: 400;
                            margin: 0;
                        }
                        input{
                            height: 40px;
                            outline: none;
                            border: 1px solid #C9CCCF;
                            border-radius: 4px;
                            padding: 9px 12px;
                        }
                    }
                }
            }
            .add{
                margin-top: 40px;
                label{
                    display: flex;
                    align-items: center;
                    margin: 0;
                    margin-bottom: 12px;
                    cursor: pointer;
                    span{
                        display: inline-block;
                        text-align: center;
                        height: 20px;
                        background: #E4E5E7;
                        border-radius: 4px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #5C5F62;
                        margin-left: 4px;
                    }
                    i{
                        margin-left: 4px;
                        width: 20px;
                        height: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 20px;
                    }
                }
                label::after{
                    display: none;
                }
                >div{
                    position: relative;
                    label{
                        font-size: 14px;
                        font-weight: 400;
                        color: #242629;
                        margin: 0;
                        margin-bottom: 12px;
                    }
                    >div{
                        margin-bottom: 24px;
                        input {
                            width: 760px;
                            min-height: 40px;
                            border: 1px solid #CED0D6;
                            border-radius: 4px;
                            outline: none;
                            cursor: pointer;
                            background: #fff;
                            padding: 9px 12px;
                            background: url(../images/arrow.png) no-repeat;
                            background-position: 98%;
                            font-size: 14px;
                            font-weight: 400;
                        }
                    
                        ul {
                            display: none;
                            width: 760px;
                            position: absolute;
                            top: -782px;
                            background: #FFFFFF;
                            box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.1), 0px 6px 16px rgba(0, 0, 0, 0.06), 0px 9px 28px 8px rgba(0, 0, 0, 0.03);
                            border-radius: 4px;
                            padding: 8px 0;
                    
                            li {
                                width: 760px;
                                height: 40px;
                                text-align: left;
                                padding: 9px 12px;
                                font-size: 14px;
                                font-weight: 400;
                                color: #262938;
                                cursor: pointer;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            }
                        }
                    }
                    >div:hover{
                        ul{
                            display: block;
                        }
                    }
                    textarea{
                        width: 760px;
                        min-height: 100px;
                        resize: none;
                        border-radius: 4px;
                        border: 1px solid #C9CCCF;
                        padding: 12px 17px;
                        outline: none;
                    }
                }
                
            }
            
        }
        .right{
            width: 392px;
            height: 422px;
            background: #FFFFFF;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15);
            border-radius: 8px;
            padding: 24px;
            display: flex;
            flex-direction: column;
            position: relative;
            >p{
                font-weight: 500;
                font-size: 18px;
                margin: 0;
                line-height: 26px;
                color: #000000;
                margin-bottom: 12px;
            }
            >span{
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                color: #242629;
                p{
                    font-size: 16px;
                    font-weight: 500;
                    color: #000000;
                    line-height: 24px;
                    margin-bottom: 24px;
                    margin-top: 4px;
                }
                span{
                    font-size: 14px;
                    font-weight: 400;
                    margin-left: 8px;
                }
            }
            input{
                width: 344px;
                height: 40px;
                background: #2C6ECB;
                color: #fff;
                border: none;
                outline: none;
                cursor: pointer;
            }
            input:hover{
                background-color: #1F5199;
            }
            input:active{
                background-color: #103262;
            }
            i{
                position: absolute;
                bottom: 32px;
                right: 120px;
                cursor: pointer;
            }
            i::before{
                color: #fff;
            }
        }
    }
}
.after {
    width: 100%;
    height: 50px;
}
@media only screen and (min-width: 300px) and (max-width: 720px) {
    body {
            .iswarning {
                div {
                    background-color: #202123;
                    color: white;
                    border-radius: 2.222vw;
    
                    >span {
                        font-size: 4.444vw;
                        font-weight: 400;
    
                        svg {
                            display: none;
                        }
                    }
                }
            }
    
            .modal {
                width: 155.556vw;
    
                div {
                    border: none;
                    width: 155.556vw;
    
                    input {
                        width: 142.222vw;
                        height: 11.111vw;
                        outline: none;
                        border: 0.278vw solid #C9CCCF;
                        border-radius: 1.111vw;
                        padding: 2.5vw 3.333vw;
                    }
    
                    button {
                        width: 22.222vw;
                        height: 11.111vw;
                        margin-left: 4.444vw;
                        border-radius: 1.111vw;
                        cursor: pointer;
                    }
    
                    .cancel {
                        border: 0.278vw solid #CED0D6;
                        background-color: #fff;
                    }
    
                    .cancel:hover {
                        background: #F6F6F7;
                    }
    
                    .cancel:active {
                        background: #F1F2F3;
                    }
    
                    .next {
                        background: #2C6ECB;
                        border: none;
                        color: #fff;
                    }
    
                    .next:hover {
                        background: #1F5199;
                    }
    
                    .next:active {
                        background: #103262;
                    }
    
                    .nextno {
                        border: none;
                        background: #BDC1CC;
                        color: #fff;
                    }
                }
            }
        }
    
        .box {
    
            .header {
                max-width: 340vw;
                margin: auto;
                margin-top: 8.889vw;
                margin-bottom: 6.667vw;
    
                p {
                    margin-bottom: 2.222vw;
                    font-weight: 700;
                    font-size: 6.667vw;
                    color: #242629;
                    line-height: 8.889vw;
                }
    
                span {
                    color: #929398;
                    font-size: 3.333vw;
                    font-weight: 400;
                    line-height: 4.444vw;
                }
            }
    
            form {
                max-width: 340vw;
                margin: auto;
                display: flex;
                justify-content: space-between;
    
                .left {
                    width: 224.444vw;
                    height: 100%;
                    background: #FFFFFF;
                    box-shadow: 0vw 0vw 1.389vw rgba(0, 0, 0, 0.05), 0vw 0.278vw 0.556vw rgba(0, 0, 0, 0.15);
                    border-radius: 2.222vw;
                    padding: 6.667vw;
                    display: flex;
                    flex-direction: column;
    
                    label {
                        font-size: 5vw;
                        font-weight: 500;
                        color: #000000;
                        margin-bottom: 3.333vw;
                        margin-top: 11.111vw;
                    }
    
                    label::after {
                        content: "*";
                        color: red;
                    }
    
                    .file {
                        width: 211.111vw;
                        height: 60vw;
                        background: #FFFFFF;
                        border: 0.278vw solid #C9CCCF;
                        border-radius: 1.111vw;
    
                        >div {
                            padding: 0 6.667vw;
                        }
    
                        .upload {
                            width: 197.778vw;
                            height: 33.333vw;
                            border-radius: 1.111vw;
                            outline: none;
                            cursor: pointer;
                            opacity: 0;
                            position: absolute;
                            margin-top: 2.778vw;
                        }
    
                        .uploaddiv {
                            margin-top: 2.778vw;
                            width: 197.778vw;
                            height: 33.333vw;
                            background: #F4F5F7;
                            border: 0.278vw dashed #CED0D6;
                            border-radius: 1.111vw;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
    
                            p {
                                font-size: 5vw;
                                font-weight: 500;
                                line-height: 7.222vw;
                                margin: 0;
                                color: #242629;
                                margin-bottom: 2.222vw;
                                display: flex;
                                align-items: center;
    
                                i {
                                    margin-right: 2.222vw;
                                }
                            }
    
                            span {
                                font-size: 3.889vw;
                                color: #929398;
                                font-weight: 400;
                                line-height: 6.111vw;
                            }
                        }
    
                    }
    
                    .fileyes {
                        width: 211.111vw;
                        height: 0;
                        overflow: hidden;
    
                    }
    
                    .requestinfono {
                        width: 211.111vw;
                        height: 0;
                        overflow: hidden;
                    }
    
                    .requestinfo {
                        width: 211.111vw;
                        height: 100%;
                        margin-bottom: 2.778vw;
    
                        .requesttop {
                            height: 21.111vw;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border-bottom: 0.278vw solid #C9CCCF;
                            padding-bottom: 4.444vw;
    
                            div {
                                display: flex;
                                align-items: center;
    
                                span {
                                    margin-left: 4.444vw;
                                    max-height: 16.667vw;
                                    max-width: 120.278vw;
                                    overflow: hidden;
                                    display: flex;
                                    flex-wrap: wrap;
                                    word-break: break-all;
                                }
    
                                img {
                                    width: 11.111vw;
                                    height: 13.333vw;
                                }
                            }
    
                            i {
                                width: 16.667vw;
                                height: 16.667vw;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
    
                            i::before {
                                font-size: 20.833vw;
                                color: #8C9196;
                            }
                        }
    
                        .requestbuttom {
                            display: flex;
                            flex-direction: column;
    
                            .requestwarn {
                                width: 211.111vw;
                                height: 23.333vw;
                                background: #EBF9FC;
                                border-radius: 2.222vw;
                                border: 0.278vw solid #98C6CD;
                                padding: 5.556vw;
                                display: flex;
                                align-items: flex-start;
    
                                span {
                                    font-size: 3.889vw;
                                    font-weight: 400;
                                }
                            }
    
                            div {
                                height: 15vw;
                                display: flex;
                                align-items: center;
                                border-bottom: 0.278vw solid #C9CCCF;
                                position: relative;
    
                                div {
                                    width: 5vw;
                                    height: 5vw;
                                    margin: 0;
                                    position: absolute;
                                    cursor: pointer;
                                    border-radius: 1.111vw;
                                    border: 0.556vw solid #8C9196;
                                }
    
                                div:hover {
                                    border: 0.556vw solid #2C6ECB;
                                }
    
                                input {
                                    width: 5vw;
                                    height: 5vw;
                                    border: none;
                                    position: absolute;
                                    border: 0.556vw solid #8C9196;
                                    border-radius: 1.111vw;
                                    opacity: 0;
                                    cursor: pointer;
                                }
    
                                span {
                                    margin-left: 7.778vw;
                                }
    
                                i {
                                    width: 5vw;
                                    height: 5vw;
                                    position: absolute;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
    
                                i::before {
                                    font-size: 6.389vw;
                                    color: #2C6ECB;
                                }
                            }
                        }
                    }
    
                    .select {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
    
                        >div {
                            input {
                                width: 101.111vw;
                                height: 11.111vw;
                                background: #FFFFFF;
                                outline: none;
                                border: 0.278vw solid #C9CCCF;
                                border-radius: 1.111vw;
                                padding: 2.5vw 3.333vw;
                                background: url(../images/arrow.png) no-repeat;
                                background-position: 98%;
                                cursor: pointer;
                                font-weight: 400;
                                font-size: 3.889vw;
                            }
    
                            ul {
                                display: none;
                                width: 101.111vw;
                                height: 26.667vw;
                                position: absolute;
                                background: #FFFFFF;
                                box-shadow: 0vw 0.833vw 1.667vw -1.111vw rgba(0, 0, 0, 0.1), 0vw 1.667vw 4.444vw rgba(0, 0, 0, 0.06), 0vw 2.5vw 7.778vw 2.222vw rgba(0, 0, 0, 0.03);
                                border-radius: 1.111vw;
                                padding: 2.222vw 0;
    
                                li {
                                    width: 101.111vw;
                                    height: 11.111vw;
                                    text-align: left;
                                    padding: 2.5vw 3.333vw;
                                    font-size: 3.889vw;
                                    font-weight: 400;
                                    color: #262938;
                                    cursor: pointer;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                }
                            }
                        }
    
                        >div:hover {
                            ul {
                                display: block;
                            }
                        }
    
                        input:hover {
                            border: 0.278vw solid #2C6ECB;
                        }
    
                        i {
                            width: 4.444vw;
                            height: 4.444vw;
                            display: flex;
                            align-items: center;
                        }
    
                        i::before {
                            font-size: 4.444vw;
                        }
                    }
    
                    .radio {
                        height: 92.222vw;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
    
                        >div {
                            width: 211.111vw;
                            height: 20.556vw;
                            border: 0.278vw solid #C9CCCF;
                            border-radius: 1.111vw;
                            display: flex;
                            margin-bottom: 3.333vw;
                            align-items: center;
    
                            input {
                                border: 0.278vw solid #2C6ECB;
                                width: 211.111vw;
                                height: 20.556vw;
                                position: absolute;
                                opacity: 0;
                                cursor: pointer;
                            }
    
                            li {
                                width: 4.722vw;
                                height: 4.722vw;
                                border: 0.278vw solid #8C9196;
                                border-radius: 27.778vw;
                                margin-left: 4.722vw;
                            }
    
                            i {
    
                                width: 5.556vw;
                                height: 5.556vw;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-left: 4.444vw;
                            }
    
                            i::before {
    
                                color: #2C6ECB;
                                font-size: 5.556vw;
                            }
    
                            div {
                                height: 11.667vw;
                                margin-left: 3.333vw;
    
                                p {
                                    font-size: 4.444vw;
                                    font-weight: 500;
                                    line-height: 6.667vw;
                                    margin: 0;
                                }
    
                                span {
                                    font-size: 3.333vw;
                                    font-weight: 400;
                                    color: #6D7175;
                                    line-height: 5vw;
                                }
                            }
                        }
    
                        .check {
                            border: 0.556vw solid #2C6ECB;
                        }
                    }
    
                    .info {
                        margin-top: 11.111vw;
    
                        div {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 1.111vw;
                            margin-top: 3.333vw;
    
                            div {
                                display: flex;
                                flex-direction: column;
                                width: 102.222vw;
                                height: 19.444vw;
                                justify-content: space-between;
    
                                label {
                                    font-size: 3.889vw;
                                    font-weight: 400;
                                    margin: 0;
                                }
    
                                input {
                                    height: 11.111vw;
                                    outline: none;
                                    border: 0.278vw solid #C9CCCF;
                                    border-radius: 1.111vw;
                                    padding: 2.5vw 3.333vw;
                                }
                            }
                        }
                    }
    
                    .add {
                        margin-top: 11.111vw;
    
                        label {
                            display: flex;
                            align-items: center;
                            margin: 0;
                            margin-bottom: 3.333vw;
                            cursor: pointer;
    
                            span {
                                display: inline-block;
                                text-align: center;
                                height: 5.556vw;
                                background: #E4E5E7;
                                border-radius: 1.111vw;
                                font-size: 3.333vw;
                                font-weight: 400;
                                color: #5C5F62;
                                margin-left: 1.111vw;
                            }
    
                            i {
                                margin-left: 1.111vw;
                                width: 5.556vw;
                                height: 5.556vw;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 5.556vw;
                            }
                        }
    
                        label::after {
                            display: none;
                        }
    
                        >div {
                            position: relative;
    
                            label {
                                font-size: 3.889vw;
                                font-weight: 400;
                                color: #242629;
                                margin: 0;
                                margin-bottom: 3.333vw;
                            }
    
                            >div {
                                margin-bottom: 6.667vw;
    
                                input {
                                    width: 211.111vw;
                                    min-height: 11.111vw;
                                    border: 0.278vw solid #CED0D6;
                                    border-radius: 1.111vw;
                                    outline: none;
                                    cursor: pointer;
                                    background: #fff;
                                    padding: 2.5vw 3.333vw;
                                    background: url(../images/arrow.png) no-repeat;
                                    background-position: 98%;
                                    font-size: 3.889vw;
                                    font-weight: 400;
                                }
    
                                ul {
                                    display: none;
                                    width: 211.111vw;
                                    position: absolute;
                                    top: -217.222vw;
                                    background: #FFFFFF;
                                    box-shadow: 0vw 0.833vw 1.667vw -1.111vw rgba(0, 0, 0, 0.1), 0vw 1.667vw 4.444vw rgba(0, 0, 0, 0.06), 0vw 2.5vw 7.778vw 2.222vw rgba(0, 0, 0, 0.03);
                                    border-radius: 1.111vw;
                                    padding: 2.222vw 0;
    
                                    li {
                                        width: 211.111vw;
                                        height: 11.111vw;
                                        text-align: left;
                                        padding: 2.5vw 3.333vw;
                                        font-size: 3.889vw;
                                        font-weight: 400;
                                        color: #262938;
                                        cursor: pointer;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                    }
                                }
                            }
    
                            >div:hover {
                                ul {
                                    display: block;
                                }
                            }
    
                            textarea {
                                width: 211.111vw;
                                min-height: 27.778vw;
                                resize: none;
                                border-radius: 1.111vw;
                                border: 0.278vw solid #C9CCCF;
                                padding: 3.333vw 4.722vw;
                                outline: none;
                            }
                        }
    
                    }
    
                }
    
                .right {
                    width: 108.889vw;
                    height: 117.222vw;
                    background: #FFFFFF;
                    box-shadow: 0vw 0vw 1.389vw rgba(0, 0, 0, 0.05), 0vw 0.278vw 0.556vw rgba(0, 0, 0, 0.15);
                    border-radius: 2.222vw;
                    padding: 6.667vw;
                    display: flex;
                    flex-direction: column;
                    position: relative;
    
                    >p {
                        font-weight: 500;
                        font-size: 5vw;
                        margin: 0;
                        line-height: 7.222vw;
                        color: #000000;
                        margin-bottom: 3.333vw;
                    }
    
                    >span {
                        font-size: 3.889vw;
                        font-weight: 400;
                        line-height: 6.111vw;
                        color: #242629;
    
                        p {
                            font-size: 4.444vw;
                            font-weight: 500;
                            color: #000000;
                            line-height: 6.667vw;
                            margin-bottom: 6.667vw;
                            margin-top: 1.111vw;
                        }
    
                        span {
                            font-size: 3.889vw;
                            font-weight: 400;
                            margin-left: 2.222vw;
                        }
                    }
    
                    input {
                        width: 95.556vw;
                        height: 11.111vw;
                        background: #2C6ECB;
                        color: #fff;
                        border: none;
                        outline: none;
                        cursor: pointer;
                    }
    
                    input:hover {
                        background-color: #1F5199;
                    }
    
                    input:active {
                        background-color: #103262;
                    }
    
                    i {
                        position: absolute;
                        bottom: 8.889vw;
                        right: 33.333vw;
                        cursor: pointer;
                    }
    
                    i::before {
                        color: #fff;
                    }
                }
            }
        }
    
        .after {
            width: 100%;
            height: 13.889vw;
        }
}