body{
    >div{
        
        background: #F5F7F8;
        p{
            font-weight: 700;
            font-size: 34px;
            color: #202223;
            margin: 0;
            line-height: 40px;
            margin-bottom: 20px;
        }
        .banner{
            max-width: 1224px;
            height: 420px;
            margin: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            >div{
                width: 650px;
                height: 232px;
                display: flex;
                flex-direction: column;
                margin-left: 108px;
                p{
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 48px;
                    color: #202223;
                    margin: 0;
                    padding-bottom: 30px;
                }
                span{
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 26px;
                    color: #202223;
                }
            }
            img{
                width: 604px;
                height: 420px;
            }
        }
        .service{
            height: 420px;
            margin: auto;
            padding: 72px 108px 96px 108px;
            background-color: #fff;
            div{
                max-width: 1224px;
                span {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 26px;
                }
                >div {
                    display: flex;
                    justify-content: space-between;
            
                    div {
                        width: 288px;
                        height: 172px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding-top: 60px;
            
                        img {
                            width: 128px;
                            height: 128px;
                        }
            
                        span {
                            font-weight: 700;
                            font-size: 20px;
                            color: #202223;
                            padding-top: 10px;
                        }
                    }
                }
            }
        }
        .clients{
            
            height: 576px;
            padding: 72px 108px 96px 108px;
            >div{
                padding-top: 28px;
                display: flex;
                justify-content: space-between;
                >div{
                    width: 386px;
                    height: 320px;
                    background: #fff;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15);
                    border-radius: 8px;
                    padding: 24px;
                    img{
                        width: 60px;
                        height: 60px;
                        border-radius: 60px;
                    }
                    p{
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 28px;
                        color: #202223;
                        margin: 0;
                        padding:  12px 0 12px 0;
                    }
                    span{
                        display: block;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: #202223;
                        margin-bottom: 16px;
                    }
                    i{
                        display: block;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: #6D7175;
                    }
                }
            }
        }
        .companies{
            background-color: #fff;
            padding: 72px 0 96px 0;
            display: flex;
            flex-direction: column;
            text-align: center;
            div{
                margin: auto;
                .swiper {
                    width: 1440px;
                    
                    >div {
                        transition-timing-function: linear !important;
                        >div {
                            width: 1832px !important;
                            max-width: 2000px !important;
                            height: 224px;
                            margin: auto;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: space-between;
            
                            img {
                                height: 80px;
                                border: none;
                                margin: 16px 20px;
                                background-color: white;
                            }
                        }
                    }
                    .slide {
                        max-width: 2000px !important;
                        width: 1832px !important;
                        margin: 0;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        
                    }
                }
            }
        }
        .quote{
            
            height: 388px;
            padding: 72px 108px 96px 108px;
            >div{
                width: 1016px;
                margin: auto;
                height: 172px;
                background: #FFFFFF;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15);
                border-radius: 8px;
                padding: 32px 56px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                p{
                    margin: 0;
                    margin-bottom: 10px;
                }
                span{
                    display: block;
                    width: 450px;
                    font-weight: 500;
                    font-size: 18px;
                    color: #6D7175;
                    line-height: 26px;
                }
            }
            button{
                width: 200px;
                height: 50px;
                background: #2C6ECB;
                border-radius: 4px;
                border: none;
                color: #fff;
                font-weight: 400;
                font-size: 18px;
                cursor: pointer;
            }
        }
        .foot{
            
            height: 589px;
            background-color: #fff;
            padding: 60px 108px 96px 108px;
            >div{
                width: 1224px;
                margin: auto;
            }
            .top{
                height: 224px;
                border-bottom: 1px solid #D9D9D9;
                display: flex;
                justify-content: space-between;
                margin-bottom: 48px;
                >div{
                    width: 25%;
                    display: flex;
                    flex-direction: column;
                    height: 224px;
                    p{
                        display: block;
                        height: 28px;
                        font-weight: 700;
                        font-size: 20px;
                        color: #202223;
                        margin: 0;
                        margin-bottom: 24px;
                    }
                    span{
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 16px;
                        color: #6D7175;
                    }
                }
            }
            .bottom{
                height: 128px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-wrap: wrap;
                div{
                    width: 33%;
                    p{
                        display: block;
                        height: 24px;
                        font-weight: 500;
                        font-size: 16px;
                        color: #202223;
                        margin: 0;
                        line-height: 24px;
                        margin-bottom: 4px;
                    }
                    span{
                        display: block;
                        height: 24px;
                        font-weight: 400;
                        font-size: 16px;
                        color: #6D7175;
                        
                    }
                }
            }
            >span{
                display: block;
                width: 1224px;
                margin: auto;
                font-weight: 400;
                font-size: 16px;
                padding-top: 48px;
                color: #6D7175;
            }
        }
    }
}
@media not screen and (min-width:300px) and (max-width: 720px) {
    body{
        >div{
            min-width: 1440px;
            .banner{
                min-width: 1440px;
            }
            .service{
                min-width: 1440px;
            }
            .clients{
                min-width: 1440px;
            }
            .companies{
                min-width: 1440px;
            }
            .foot{
                min-width: 1440px;
                .top{
                    display: flex;
                }
                .mobiletop{
                    display: none;
                }
            }
        }
    }
}
@media only screen and (min-width:300px) and (max-width: 720px){
    body {
        >div {
            width: 100vw;

            .banner {
                width: 100vw;
                height: 100%;
                display: flex;
                flex-direction: column-reverse;
                div{
                    margin: 0;
                    padding: 8.889vw 5.556vw;
                    width: 100vw;
                    height: 100% ;
                  p{
                    font-weight: 700;
                    font-size: 6.667vw;
                    line-height: 8.889vw;
                    padding: 0;
                    margin-bottom: 4.444vw;
                  }
                  span{
                    font-weight: 400;
                    font-size: 3.889vw;
                    line-height: 6.111vw;
                  }
                }
                img{
                    width: 100vw;
                    height: 69.444vw;
                }
            }

            .service {
                width: 100vw;
                height: 107.778vw;
                margin: 0;
                padding: 8.889vw 5.556vw;
                
                >div{
                    p {
                        font-weight: 700;
                        font-size: 5.556vw;
                        line-height: 7.778vw;
                        margin-bottom: 2.222vw;
                    }
                
                    >span {
                        width: 88.889vw;
                        font-weight: 400;
                        font-size: 3.889vw;
                        line-height: 6.111vw;
                        }
                    div{
                        width: 88.889vw;
                        height: 63.333vw;
                        flex-wrap: wrap;
                        margin-top: 1.667vw;
                        div{
                            width: 42.222vw;
                            height: 28.333vw;
                            padding-top: 6.667vw;
                            padding: 0;
                            display: flex;
                            flex-direction: column;
                            img{
                                width: 20vw;
                                height: 20vw;
                            }
                            span{
                                display: inline;
                                padding: 0;
                                font-weight: 400;
                                font-size: 3.889vw;
                            }
                            }
                    }
                }
            }

            .clients {
                width: 100vw;
                height: 100%;
                margin: 0;
                padding: 8.889vw 5.556vw;
                p{
                    font-weight: 700;
                    font-size: 5.556vw;
                    line-height: 7.778vw;
                    margin-bottom: 2.222vw;
                }
                >div{
                    flex-direction: column;
                    padding-top: 2.222vw;
                    >div{
                        width: 88.889vw;
                        height: 100%;
                        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15);
                        border-radius: 2.222vw;
                        padding: 4.444vw;
                        margin-bottom: 4.444vw;
                        img{
                            width: 16.667vw;
                            height: 16.667vw;
                        }
                        p{
                            font-weight: 500;
                            font-size: 4.444vw;
                            padding: 3.333vw 0;
                        }
                        span{
                            font-weight: 400;
                            font-size: 3.889vw;
                            line-height: 6.111vw;
                            margin-bottom: 3.333vw;
                        }
                        i{
                            font-weight: 400;
                            font-size: 3.889vw;
                            line-height: 6.111vw;
                            font-style: normal !important;
                        }
                    }
                }
            }
            .quote{
                width: 100vw;
                height: 83.889vw;
                padding: 8.889vw 5.556vw;
                
                >div{
                    width: 88.889vw;
                    height: 66.111vw;
                    padding: 6.667vw;
                    display: flex;
                    flex-direction: column;
                    p{
                        margin-bottom: 4.444vw;
                        font-weight: 700;
                        font-size: 5.556vw;
                        line-height: 7.778vw;
                        text-align: center;
                    }
                    span{
                        width: 75.556vw;
                        height: 13.889vw;
                        font-weight: 400;
                        font-size: 3.889vw;
                        line-height: 6.111vw;
                        display: flex;
                        text-align: center;
                        flex-direction: column;
                        align-items: center;
                    }
                }
                button{
                    width: 75.556vw;
                    height: 13.333vw;
                    font-weight: 500;
                    font-size: 4.444vw;
                }
            }
            .companies {
                width: 100vw;
                height: 100%;
                padding:  32px 0;
                div {
                    width: 100vw !important;
                    p {
                        width: 320px;
                        font-weight: 700;
                        font-size: 5.556vw;
                        line-height: 7.778vw;
                        margin: auto;
                        margin-bottom: 6.667vw;
                    }
                    .swiper {
                        width: 100vw;
                        >div {
                            transition-timing-function: linear !important;

                            >div {
                                width: 294.722vw !important;
                                height: 35.556vw;
                                margin: auto;
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                justify-content: space-between;

                                img {
                                    height: 13.333vw;
                                    border: none;
                                    margin: 4.444vw 2.778vw;
                                    background-color: white;
                                }
                            }
                        }

                        .slide {
                            width: 294.722vw !important;
                        }
                    }
                }

                div {
                    width: 294.722vw;
                    height: 100%;
                }
            }

            .foot {
                width: 100vw;
                height: 100%;
                padding: 8.889vw 5.556vw;
                >div{
                    width: 88.889vw;
                }
                .top{
                    display: none;

                }
                .mobiletop{
                    display: block;
                    height: 100%;
                    border-bottom: 1px solid #D9D9D9;
                    margin-bottom: 6.667vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-bottom: 2.222vw;
                    margin-top: -6.667vw;
                    >div{
                        width: 88.889vw;
                        height: 6.111vw;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 6.667vw;
                        font-weight: 700;
                        font-size: 3.889vw;
                        color: #202223;
                        margin-bottom: 2.222vw;
                        i{
                            width: 5.556vw;
                            height: 5.556vw;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        i::before{
                            font-weight: 500;
                            font-size: 5.556vw;
                            color: #5C5F62;
                        }
                    }
                    .topexpanse{
                        margin: 0;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        span {
                            font-weight: 400;
                            font-size: 3.889vw;
                            color: #6D7175;
                            line-height: 8.333vw;
                        }
                    }
                    
                }
                .bottom{
                    height: 102.222vw;
                    flex-direction: column;
                    >div{
                        width: 88.889vw;
                        height: 13.333vw;
                        p{
                            font-weight: 400;
                            font-size: 3.889vw;
                        }
                        span{
                            font-size: 3.889vw;
                        }
                    }
                }
                >span{
                    width: 88.889vw;
                    padding-top: 6.667vw;
                    font-size: 3.333vw;
                }
            }
        }
    }
}