* {
    margin: 0;
    padding: 0;
    font-family: "DM sans";
    body {
            // min-width: 1000px;
            a {
                text-decoration: none;
                color: black;
            }
            li{
                list-style: none;
                text-align: center;
            }
            .m_t25 {
                margin-top: 25px;
            }

            .m_t15 {
                margin-top: 15px;
            }

            .tar {
                text-align: right;
            }

            .m_r10 {
                margin-right: 10px;
            }

            .test {
                background-color: #fff;
            }
 }
}
@media not screen and (min-width:300px) and (max-width: 720px){
    #root{
        min-width: 1440px;
    }
}
@media only screen and (min-width: 300px) and (max-width: 720px) {
    #root{
        width: 100vw;
        >div{
            width: 100vw;
        }
    }
}