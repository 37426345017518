body {
    >div {
        background: #F5F7F8;
        p {
            font-weight: 700;
            font-size: 34px;
            color: #202223;
            margin: 0;
            line-height: 40px;
            margin-bottom: 20px;
        }
        .ourbox{
            background: #fff;
            >div{
                max-width: 1224px;
                margin: auto;
                padding: 72px 0;
                p{
                    width: 800px;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 48px;
                    margin-bottom: 48px;
                }
                >div{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 48px;
                    img{
                        width: 384px;
                        height: 256px;
                    }
                    div{
                        margin: 0;
                        width: 792px;
                        height: 256px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        span {
                            font-weight: 400;
                            font-size: 16px;
                            color: #202223;
                            line-height: 24px;
                        }
                    }
                }
                .bottom{
                    height: 100%;
                    min-height: 256px;
                }
            }
        }
        .process{
            width: 1440px;
            height: 596px;
            background: #F5F7F8;
            margin: auto;
            padding: 72px 108px;
            >p{
                margin-bottom: 64px;
            }
            ul{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                li{
                    width: 288px;   
                    height: 156px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                    margin-bottom: 40px;
                    >div{
                        width: 84px;
                        height: 84px;
                        border-radius: 100px;
                        background: #FFFFFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        i{
                            width: 48px;
                            height: 48px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        i::before{
                            font-size: 40px;
                            color: #2C6ECB;
                        }
                        img{
                            width: 48px;
                            height: 48px;
                        }
                    }
                    p{
                        width: 288px;
                        height: 56px;
                        text-align: left;
                        font-weight: 500;
                        font-size: 20px;
                        color: #202223;
                        line-height: 28px;
                        margin: 0;
                    }
                }
            }
        }
        .foot {
            height: 589px;
            background-color: #fff;
            padding: 60px 108px 96px 108px;

            >div {
                width: 1224px;
                margin: auto;
            }
            .mobiletop{
                display: none;
            }
            .top {
                height: 224px;
                border-bottom: 1px solid #D9D9D9;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-bottom: 48px;

                >div {
                    width: 25%;
                    display: flex;
                    flex-direction: column;
                    margin: 0;
                    p {
                        display: block;
                        height: 28px;
                        font-weight: 700;
                        font-size: 20px;
                        color: #202223;
                        margin: 0;
                        margin-bottom: 24px;
                    }

                    span {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 16px;
                        color: #6D7175;
                    }
                }
            }

            .bottom {
                height: 128px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-wrap: wrap;

                div {
                    width: 33%;

                    p {
                        display: block;
                        height: 24px;
                        font-weight: 500;
                        font-size: 16px;
                        color: #202223;
                        margin: 0;
                        line-height: 24px;
                        margin-bottom: 4px;
                    }

                    span {
                        display: block;
                        height: 24px;
                        font-weight: 400;
                        font-size: 16px;
                        color: #6D7175;

                    }
                }
            }

            >span {
                display: block;
                width: 1224px;
                margin: auto;
                font-weight: 400;
                font-size: 16px;
                padding-top: 48px;
                color: #6D7175;
            }
        }
    }
}
@media not screen and (min-width:300px) and (max-width: 720px) {
    >div {
        min-width: 1440px;
        .ourbox{
            min-width: 1440px;
        }
        .foot {
            min-width: 1440px;
            .top{
                display: flex;
            }
            .mobiletop{
                display: none;
            }
        }
    }
}
@media only screen and (min-width:300px) and (max-width: 720px) {
    body{
        width: 100vw;
        .iswarning {
                div {
                    background-color: #202123;
                    color: white;
                    border-radius: 2.222vw;
        
                    >span {
                        font-size: 4.444vw;
                        font-weight: 400;
        
                        svg {
                            display: none;
                        }
                    }
                }
            }
        >div{
            width: 100vw;
            .ourbox{
                border-top: 1px solid #ECECEC;
                width: 100vw;
                padding: 6.667vw 5.556vw;
                >div{
                    width: 88.889vw;
                    padding: 0;
                    >p{
                        width: 88.889vw;
                        font-size: 6.667vw;
                        line-height: 8.889vw;
                        margin-bottom: 4.444vw;
                    }
                    >div{
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 4.444vw;
                        img{
                            width: 88.889vw;
                            margin-bottom: 4.444vw;
                        }
                        >div{
                            width: 88.889vw;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            span{
                            font-weight: 400;
                            font-size: 4.444vw;
                            line-height: 6.667vw;
                            margin-bottom: 8.333vw;
                            }
                        }
                    }
                    .bottomimg{
                        display: flex;
                        flex-direction: column-reverse;
                        >div{
                            height: 100%;
                        }
                    }
                }
            }
            .process{
                width: 100vw;
                height: 100%;
                padding: 6.667vw 5.556vw;
                p{
                    margin: 0;
                    font-weight: 700;
                    font-size: 5vw;
                }
                ul{
                    flex-wrap: wrap;
                    li{
                        width: 42.222vw;
                        height: 30vw;
                        margin-bottom: 6.667vw;
                        div{
                            width: 17.778vw;
                            height: 17.778vw;
                            i{
                                width: 10vw;
                                height: 10vw;
                            }
                            i::before{
                                font-size: 10vw;
                            }
                            img{
                                width: 10vw;
                                height: 10vw;
                            }
                        }
                        p{
                            height: 10vw;
                            width: 42.222vw;
                            font-weight: 400;
                            font-size: 3.333vw;
                            display: flex;
                            flex-wrap: wrap;
                            line-height: 5vw;
                        }
                    }
                }
            }
            .foot {
                width: 100vw;
                height: 100%;
                padding: 8.889vw 5.556vw;
        
                >div {
                    width: 88.889vw;
                }
        
                .top {
                    display: none;
                }
        
                .mobiletop {
                    height: 100%;
                    border-bottom: 1px solid #D9D9D9;
                    margin-bottom: 6.667vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-bottom: 2.222vw;
                    margin-top: -6.667vw;
        
                    >div {
                        width: 88.889vw;
                        height: 6.111vw;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 6.667vw;
                        font-weight: 700;
                        font-size: 3.889vw;
                        color: #202223;
                        margin-bottom: 2.222vw;
        
                        i {
                            width: 5.556vw;
                            height: 5.556vw;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
        
                        i::before {
                            font-weight: 500;
                            font-size: 5.556vw;
                            color: #5C5F62;
                        }
                    }
        
                    .topexpanse {
                        margin: 0;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
        
                        span {
                            font-weight: 400;
                            font-size: 3.889vw;
                            color: #6D7175;
                            line-height: 8.333vw;
                        }
                    }
        
                }
        
                .bottom {
                    height: 102.222vw;
                    flex-direction: column;
        
                    >div {
                        width: 88.889vw;
                        height: 13.333vw;
        
                        p {
                            font-weight: 400;
                            font-size: 3.889vw;
                        }
        
                        span {
                            font-size: 3.889vw;
                        }
                    }
                }
        
                >span {
                    width: 88.889vw;
                    padding-top: 6.667vw;
                    font-size: 3.333vw;
                }
            }
        }
    }
}