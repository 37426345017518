body{
    background: #D9D9D9;
    .iswarning {
            div {
                background-color: #202123;
                color: white;
                border-radius: 8px;
    
                >span {
                    font-size: 16px;
                    font-weight: 400;
    
                    svg {
                        display: none;
                    }
                }
            }
        }
}
.header{
    height: 72px;
    background-color: #fff;
    padding:  0 24px;
    display: flex;
    justify-content: space-between;
    .headerLeft{
        height: 72px;
        display: flex;
        align-items: center;
        .logo{
            width: 104px;
            overflow: hidden;
            margin-right: 69px;
            cursor: pointer;
            img {
                    width: 105px;
                    height: 40px;
                    border: none;
                }
        }
        span {
            height: 72px;
            display: inline-block;
            margin-right: 44px;
            font-weight: 500;
            line-height: 72px;
            font-size: 16px;
            color: #6D7175;
            border: none;
            cursor: pointer;
        }

        span:hover {
            color: black;
            border-bottom: 4px solid #2C6ECB;
        } 
        button{
            height: 72px;
            display: inline-block;
            color: #6D7175;
            border: none;
            padding: 0;
            margin-right: 44px;
            cursor: pointer;
            border-radius: 0;
            span{
                height: 72px;
                margin: 0;
            }
        }
        button::after{
            border: 0 none;
            opacity: 0;
            animation: none 0 ease 0 1 normal;
        }
        .spanclick{
            height: 72px;
            display: inline-block;
            font-weight: 500;
            line-height: 72px;
            font-size: 16px;
            border-bottom: 4px solid #2C6ECB;
            cursor: pointer;
            span{
                color: black;
            }
        }
    }
    .headerRight{
        height: 72px;
        display: flex;
        align-items: center;
        .mobile {
            display: none;
            width: 6.667vw;
            height: 6.667vw;
            margin-left: 2.778vw;
            i{ 
                width: 6.667vw;
                height: 6.667vw;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            i::before {
                font-size: 6.667vw;
                color: #202223;
            }
        }
        div{
            height: 32px;
            font-weight: 400;
            font-size: 14px;
            color: #6D7175;
            display: flex;
            align-items: center;
            cursor: pointer;
            i{
                margin-left: 7px;
            }
        }
        .engorkor{
            width: 86px;
            height: 40px;
            display: flex;
            align-items: center;
            padding-left: 12px;
            border-radius: 4px;
        }
        .engorkor:hover{
            background-color: #F1F2F3;
        }
        button{
            display: none;
        }
        .sign{
            width: 80px;
            height: 40px;
            background-color: #2C6ECB;
            border: none;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
}
.modal {
    border-radius: 8px;
    margin-top: -50px;
    .last{
        font-weight: 400;
        font-size: 14px;
        margin: 24px 0 8px 0;
    }
    >div {
        width: 560px;
        white-space: nowrap;
        border: none;
        font-weight: 700;
        font-size: 16px;
        padding-top: 0;
        >div {
            border: none;
            
            >div{
                font-weight: 700;
                font-size: 16px;
                color: #242629;
            }
        }
        .plase{
            display: flex;
            height: 50px;
            white-space: normal;
            margin-top: -24px;
            font-weight: 400;
            font-size: 14px;
            color: #6D7175;
        }
        p{
            display: inline-block;
            height: 22px;
            width: 244px;
            font-weight: 400;
            font-size: 14px;
            color: #242629;
            margin: 0;
            line-height: 22px;
            margin-right: 24px;
            margin-bottom: 8px;
        }
        p::after{
            content: "*";
            color: red;
            margin-left: 2px;
        }
        input{
            width: 242px;
            height: 38px;
            border: 1px solid #C9CCCF;
            border-radius: 4px;
            margin-right: 24px;
            margin-bottom: 21px;
            
        }
        .inquirytype{
            width: 510px;
            height: 40px;
            border: 1px solid #CED0D6;
            border-radius: 4px;
            font-weight: 400;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #242629;
            margin-bottom: 21px;
        }
        .cancel {
            width: 79px;
            height: 40px;
            border: 1px solid #CED0D6;
            border-radius: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 40px;
            color: #242629;
            padding: 0;
        }
        .cancel:hover {
            background: #F6F6F7;
        }

        .cancel:active {
            background: #F1F2F3;
        }
        
        .select {
            width: 79px;
            height: 40px;
            border: 1px solid #CED0D6;
            border-radius: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 40px;
            background: #2C6ECB;
            color: #FFFFFF;
            padding: 0;
        }
        .select:hover {
            background: #1F5199;
        }

        .select:active {
            background: #103262;
        }
        
        .dropdown {
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 21px;
            color: #6D7175;
            a {
                width: 244px;
                height: 40px;
                display: block;
                border: 1px solid #CED0D6;
                border-radius: 4px;
                padding: 0 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                >div {
                    font-weight: 400;
                    font-size: 14px;
                    color: #9B9DA9;
                }

                .divclick {
                    font-weight: 400;
                    font-size: 14px;
                    color: #242629;
                }

                i::before {
                    font-size: 16px;
                    color: #5A607D;
                }
            }

            i::before {
                font-size: 16px;
                color: #9BA3BE;
                font-weight: lighter;
            }

        }
        .url{
            display: flex;
            flex-direction: column;
            div{
                width: 512px;
                height: 40px;
                margin-bottom: 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                input {
                    width: 100%;
                    margin: 0;
                }
                .input{
                    width: 480px;
                    margin: 0;
                }
                button{
                    width: 24px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #FFFFFF;
                    border-radius: 4px;
                    border: 1px solid #C9CCCF;
                    cursor: pointer;
                    i{
                        width: 24px;
                        height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    i::before{
                        font-size: 20px;
                    }
                }
                button:hover{
                    background: #F6F6F7;   
                }
                button:active{
                    background: #F1F2F3;
                }
            }
            span{
                width: 119px;
                height: 32px;
                font-weight: 500;
                font-size: 14px;
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                color: #2C6ECB;
                cursor: pointer;
                i{
                    margin-right: 4px;
                    width: 16px;
                    height: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                i::before{
                    font-size: 16px;
                    color: #2C6ECB;
                }
            }
        }
        .textarea {
            position: relative;
            border: 1px solid #C9CCCF;
            border-radius: 4px;
            padding-bottom: 20px;
            box-shadow: none;
            textarea {
                min-height: 66px;
                border-radius: 4px;
                border: none;
                resize: none;
                box-shadow: none;
            }
        }
        .textarea::after {
            position: absolute;
            font-weight: 400;
            font-size: 12px;
            right: 10px;
            bottom: 0;
            color: #6D7175;
        }

    }
}


@media not screen and (min-width:300px) and (max-width: 720px)  {
   .header{
    min-width: 1440px;
   } 
   
}
@media only screen and (min-width:300px) and (max-width: 720px) {
    
    .header {
        width: 100vw;
        height: 17.778vw;
        background-color: #fff;
        padding: 0 5.556vw 0 2.778vw;
        .headerLeft {
            height: 17.778vw;
            .logo {
                padding: 0;
                width: 24.7vw;
                height: 8.889vw;
                overflow: hidden;
                margin-right: 69px;
                img {
                    width: 20.556vw;
                    height: 8.889vw;
                }
            }
            span {
                display: none;
            }
            button {
                display: none;
            }
            .spanclick {
                display: none;
            }
        }

        .headerRight {
            height: 17.778vw;
            .mobile {
                display: block;
            }
            .engorkor {
                display: none;
            }
            button {
                display: block;
                width: 36.944vw;
                height: 8.889vw;
                background: #FFFFFF;
                border: 1px solid #CED0D6;
                border-radius: 1.111vw;
                font-weight: 400;
                font-size: 3.333vw;
            }
            .sign {
                display: block;
                width: 22.222vw;
                height: 8.889vw;
                font-size: 3.889vw;
            }
        }
    }
}