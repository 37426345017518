body{
    background-color: #F6F6F7;
    font-family: "DM sans";
    .iswarning{
        div{
            background-color: #202123;
            color: white;
            border-radius: 8px;
            >span{
                font-size: 16px;
                font-weight: 400;
                svg{
                    display: none;
                }
            }
        }
    }
}
.header{
    min-width: 1224px;
    height: 72px;
    width: 100%;
    background-color: #FFFFFF;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;
    .left{
        display: flex;
        align-items: center;
        .icon {
            height: 20px;
            border-right: 1px solid #D9D9D9;
            padding-right: 21px;
            margin-right: 16px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    
        i::before {
            width: 20px;
            height: 20px;
            font-size: 20px;
            font-weight: bold;
            margin-left: 37px;
            
        }
        span{
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }
    }
    .right {
        margin-right: 32px;
        display: flex;
        flex-direction: row-reverse;

        button {
            width: 80px;
            height: 40px;
            margin-left: 16px;
            border-radius: 4px;
            font-weight: 500;
            border: 1px solid #CED0D6;
            padding: 0 16px 0 16px;
            cursor: pointer;
        }
        .one {
            background-color: #2C6ECB;
        }
        .one:hover{
            background: #1F5199;
        }
        .one:active{
            background: #103262;
        }
        .two{
            width: 89px;
        }
        .two:hover{
            background: #F6F6F7;
            color: black;
        }
        .two:active{
            background: #F1F2F3;
            color: black;
        }
        
    }
}
.process{
    min-width: 1224px;
    width: 1224px;
    height: 30px;
    display: flex;
    margin: auto;
    margin-bottom: 32px;
    >div{
        width: 264px;
        display: flex;
        align-items: center;
        border-top: 4px solid #C1C9D2;
        margin-right: 8px;
        i{
            width: 20px;
            height: 20px;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        i::before {
            font-size: 16px;
            color: #8C9196;
        }

        span{
            font-weight: 500;
            font-size: 14px;
            color: #8C9196
        }
    }
    .divclick{
        border-top: 4px solid #2C6ECB;
        i::before {
            color: #2C6ECB;
        }
        span{
            color: #202223;
        }
    }
    .divfinish{
        border-top: 4px solid #458FFF;
        i::before {
            color: #458FFF;
        }
        span {
            color: #6D7175;
        }
    }
}
.box {
    min-width: 1224px;
    width: 100%;
    margin-bottom: 32px;
    .title{
        min-width: 1224px;
        width: 1224px;
        min-height: 116px;
        margin: auto;
        background: #FFFFFF;
        border-radius: 8px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15);
        .top{
            padding: 24px 32px 0 32px;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            color: #202223;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;
            i{
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            i::before{
                font-size: 32px;
                font-weight: 500;
            }
        }
        .body {
            width: 100%;
            border-top: 1px solid #CED0D6;
            padding: 32px 32px 8px 32px;
            .speciality{
                display: flex;
                height: 120px;
                flex-direction: column;
                p{
                    height: 32px;
                    
                    cursor: pointer;
                    margin: 0;
                }
                >ul {

                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    >li {
                        padding: 0 12px;
                        height: 36px;
                        border-radius: 4px;
                        margin-right: 12px;
                        font-weight: 400;
                        line-height: 36px;
                        font-size: 16px;
                        background: #E4E5E7;
                        color: black;
                    }
                    p{
                        font-size: 14px;
                        color: #2C6ECB;
                        font-weight: 500;
                    }
                }
                .modal{
                    border-radius: 8px;
                    >div{
                        width: 560px;
                        white-space: nowrap;
                        border: none;
                        font-weight: 700;
                        font-size: 16px;
                        div{
                            border: none;
                        }
                        p{
                            font-weight: 400;
                            font-size: 14px;
                        }
                        .checkbox{
                            width: 560px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-wrap: wrap;
                            label{
                                width: 50%;
                                font-weight: 400;
                                font-size: 14px;
                                margin: 0;
                                padding-top: 10px;
                                padding-bottom: 14px;
                                input{
                                    width: 220px;
                                }
                                input:checked + span{
                                    border: 2px solid #2C6ECB;
                                    background: #2C6ECB;
                                }
                                >span{
                                    height: 16px;
                                    padding: 0;
                                    border-radius: 4px;
                                    margin-right: 9px;
                                    span{
                                        width: 16px;
                                        height: 16px;
                                        border: 2px solid #8C9196;
                                        border-radius: 4px;
                                    }
                                }

                            }
                            label:hover{
                                >span{
                                    span{
                                        border: 2px solid #2C6ECB;
                                    }
                                }
                            }
                        }
                        .cancel {
                            width: 79px;
                            height: 40px;
                            border: 1px solid #CED0D6;
                            border-radius: 4px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 40px;
                            color: #242629;
                            padding: 0;
                        }
                        .select{
                            width: 79px;
                            height: 40px;
                            border: 1px solid #CED0D6;
                            border-radius: 4px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 40px;
                            background: #2C6ECB;
                            color: #FFFFFF;
                            padding: 0;
                        }
                        .noselect{
                            width: 79px;
                            height: 40px;
                            border: 1px solid #CED0D6;
                            border-radius: 4px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 40px;
                            background: #BDC1CC;
                            color: #FFFFFF;
                            padding: 0;
                        }
                    }
                }
            }
            .pa {
                cursor: pointer;
                width: 200px;
                font-size: 16px;
                color: #2C6ECB;
                font-weight: 500;
            }
            > div{
                font-weight: 600;
                font-size: 18px;
                padding-bottom: 28px;
                >span{
                    margin-bottom: 12px;
                }
                >p{
                    display: block;
                    margin: 0;
                    height: 32px;
                    font-weight: 500;
                    font-size: 14px;
                    color: #2C6ECB;
                    display: flex;
                    align-items: center;
                    margin-bottom: 28px;
                    i{
                        width: 16px;
                        height: 16px;
                        display: flex;
                        align-items: center;
                        margin-right: 7px;
                    }
                    i::before{
                        font-size: 16px;
                    }
                }
                input{
                    min-height: 40px;
                    border-radius: 4px;
                }
                .radio{
                    label{
                        >span{
                            height: 18px;
                            padding: 0;
                            margin-right: 9px;
                            font-weight: 400;
                            font-size: 14px;
                            color: #202223;
                            input {
                                width: 86px;
                            }
                        
                            input:checked+span {
                                border: 2px solid #2C6ECB;
                            }
                            input:checked+span::after {
                                background: #2C6ECB;
                            }
                            span{
                                width: 18px;
                                height: 18px;
                                border: 2px solid #8C9196;
                                border-radius: 100%;
                            }
                        }
                    }
                    input{
                        width: 121px;
                        height: 40px;
                        background: #F4F5F7;
                        border: 1px solid #C9CCCF;
                        border-radius: 4px;
                        margin-right: 8px;
                    }
                    span{
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                    }
                }
                .textarea{
                    position: relative;
                    border: 1px solid #C9CCCF;
                    border-radius: 4px;
                    padding-bottom: 20px;
                    box-shadow: none;
                    margin-top: 12px;
                }
                .textarea::after {
                        position: absolute;
                        font-weight: 400;
                        font-size: 12px;
                        right: 10px;
                        bottom: 0;
                    }
                .textareatwo{
                    position: relative;
                    border: 1px solid #C9CCCF;
                    border-radius: 4px;
                    padding-bottom: 20px;
                    box-shadow: none;
                    margin-top: 40px;
                    margin-bottom: 17px;
                }
                .textareathree{
                    height: 154px;
                    position: relative;
                    border: 1px solid #C9CCCF;
                    border-radius: 4px;
                    padding-bottom: 20px;
                    box-shadow: none;
                    margin-top: 12px;
                }
                .textareathree::after{
                    display: none;
                    
                }
                .textareatwo::after {
                        position: absolute;
                        font-weight: 400;
                        font-size: 12px;
                        right: 10px;
                        bottom: 0;
                    }
                
                textarea{
                    min-height: 68px;
                    border-radius: 4px;
                    border: none;
                    resize: none;
                    box-shadow: none;
                }
                
                
            }
            input{
                width: 765px;
            }
            .inputbottom{
                font-weight: 400;
                font-size: 12px;
                color: #8C9196;
                padding-top: 8px;
                border: none;
            }
            .threeinput {
                padding-bottom: 0;
                margin-top: 12px;
                .lasttext{
                    font-weight: 400;
                        font-size: 14px;
                        color: #242629;
                }
                .bordertop{
                    width: 100%;
                    height: 5px;
                    border-top: 1px solid #C9CCCF;
                }
                >span{
                    display: block;
                    button{
                        width: 24px;
                        height: 24px;
                        background: #F6F6F7;
                        border: 1px solid #C9CCCF;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        i{
                            width: 24px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
                >p{
                    width: 370px;
                    cursor: pointer;
                }
                >p:hover{
                    color: #1F5199;
                }
                .input{
                    display: flex;
                    justify-content: left !important;
                    margin: 12px 0;
                    >div {
                        width: 370px;
                        height: 70px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin-right: 24px;
                        margin-left: 0;
                        p {
                            height: 22px;
                            font-weight: 400;
                            font-size: 14px;
                            color: #242629;
                            margin: 0;
                            line-height: 22px;
                            button{
                                width: 24px;
                                height: 24px;
                                background-color: #fff;
                                border: 1px solid #C9CCCF;
                                border-radius: 4px;
                                cursor: pointer;
                                i::before{
                                    font-size: 20px;
                                    color: #6D7175;
                                }
                            }
                            button:hover{
                                background: #F6F6F7;
                            }
                            button:active{
                                background: #F1F2F3;
                            }
                        }
                        input{
                            margin-top: 8px;
                            width: 370px;
                        }
                    }
                    .period{
                        width: 766px;
                        p{
                            width: 766px;
                            margin-bottom: 8px;
                            
                        }
                        >div{
                            height: 40px;
                            width: 370px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            border: 1px solid #C9CCCF;
                            border-radius: 4px;
                            margin: 0;
                            >p{
                                font-weight: 400;
                                font-size: 14px;
                                color: #6D7175;
                                margin-right: 20px;
                                margin-bottom: 0;
                            }
                            .date {
                                width: 156px;
                                padding: 0;
                                height: 40px;
                                >div {
                                    width: 156px;
                                    height: 40px;
                                    input {
                                        padding-left: 12px;
                                        width: 144px;
                                        margin: 0;
                                    }
                                }
                                span {
                                    display: none;
                                }
                            }
                        }
                        .inputbottom{
                            border: none;
                            color:  #F53333;
                        }
                        .erro{
                            border: 1px solid #FD5749;
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
    }

}
.after{
    width: 100%;
    height: 50px;
}
@media not screen and (min-width:300px) and (max-width: 720px) {
    body {
        >div {
            min-width: 1440px;
            .header{
                min-width: 1440px;
            }
            .box{
                min-width: 1440px;
            }
        }
    }
}

@media only screen and (min-width:300px) and (max-width: 720px){
    .iswarning {
        width: 100vw;
            div {
                background-color: #202123;
                color: white;
                border-radius: 2.222vw;
    
                >span {
                    font-size: 4.444vw;
                    font-weight: 400;
    
                    svg {
                        display: none;
                    }
                }
            }
        }
.header {
    min-width: 1224px;
    height: 72px;
    width: 100%;
    background-color: #FFFFFF;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;

    .left {
        display: flex;
        align-items: center;

        .icon {
            height: 20px;
            border-right: 1px solid #D9D9D9;
            padding-right: 21px;
            margin-right: 16px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        i::before {
            width: 20px;
            height: 20px;
            font-size: 20px;
            font-weight: bold;
            margin-left: 37px;

        }

        span {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }
    }

    .right {
        margin-right: 32px;
        display: flex;
        flex-direction: row-reverse;

        button {
            width: 80px;
            height: 40px;
            margin-left: 16px;
            border-radius: 4px;
            font-weight: 500;
            border: 1px solid #CED0D6;
            padding: 0 16px 0 16px;
            cursor: pointer;
        }

        .one {
            background-color: #2C6ECB;
        }

        .one:hover {
            background: #1F5199;
        }

        .one:active {
            background: #103262;
        }

        .two {
            width: 89px;
        }

        .two:hover {
            background: #F6F6F7;
            color: black;
        }

        .two:active {
            background: #F1F2F3;
            color: black;
        }

    }
}

.process {
    min-width: 1224px;
    width: 1224px;
    height: 30px;
    display: flex;
    margin: auto;
    margin-bottom: 32px;

    >div {
        width: 264px;
        display: flex;
        align-items: center;
        border-top: 4px solid #C1C9D2;
        margin-right: 8px;

        i {
            width: 20px;
            height: 20px;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        i::before {
            font-size: 16px;
            color: #8C9196;
        }

        span {
            font-weight: 500;
            font-size: 14px;
            color: #8C9196
        }
    }

    .divclick {
        border-top: 4px solid #2C6ECB;

        i::before {
            color: #2C6ECB;
        }

        span {
            color: #202223;
        }
    }

    .divfinish {
        border-top: 4px solid #458FFF;

        i::before {
            color: #458FFF;
        }

        span {
            color: #6D7175;
        }
    }
}

.box {
    min-width: 1224px;
    width: 100%;
    margin-bottom: 32px;

    .title {
        min-width: 1224px;
        width: 1224px;
        min-height: 116px;
        margin: auto;
        background: #FFFFFF;
        border-radius: 8px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15);

        .top {
            padding: 24px 32px 0 32px;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            color: #202223;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;

            i {
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            i::before {
                font-size: 32px;
                font-weight: 500;
            }
        }

        .body {
            width: 100%;
            border-top: 1px solid #CED0D6;
            padding: 32px 32px 8px 32px;

            .speciality {
                display: flex;
                height: 120px;
                flex-direction: column;

                p {
                    height: 32px;

                    cursor: pointer;
                    margin: 0;
                }

                >ul {

                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    >li {
                        padding: 0 12px;
                        height: 36px;
                        border-radius: 4px;
                        margin-right: 12px;
                        font-weight: 400;
                        line-height: 36px;
                        font-size: 16px;
                        background: #E4E5E7;
                        color: black;
                    }

                    p {
                        font-size: 14px;
                        color: #2C6ECB;
                        font-weight: 500;
                    }
                }

                .modal {
                    border-radius: 8px;

                    >div {
                        width: 560px;
                        white-space: nowrap;
                        border: none;
                        font-weight: 700;
                        font-size: 16px;

                        div {
                            border: none;
                        }

                        p {
                            font-weight: 400;
                            font-size: 14px;
                        }

                        .checkbox {
                            width: 560px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-wrap: wrap;

                            label {
                                width: 50%;
                                font-weight: 400;
                                font-size: 14px;
                                margin: 0;
                                padding-top: 10px;
                                padding-bottom: 14px;

                                input {
                                    width: 220px;
                                }

                                input:checked+span {
                                    border: 2px solid #2C6ECB;
                                    background: #2C6ECB;
                                }

                                >span {
                                    height: 16px;
                                    padding: 0;
                                    border-radius: 4px;
                                    margin-right: 9px;

                                    span {
                                        width: 16px;
                                        height: 16px;
                                        border: 2px solid #8C9196;
                                        border-radius: 4px;
                                    }
                                }

                            }

                            label:hover {
                                >span {
                                    span {
                                        border: 2px solid #2C6ECB;
                                    }
                                }
                            }
                        }

                        .cancel {
                            width: 79px;
                            height: 40px;
                            border: 1px solid #CED0D6;
                            border-radius: 4px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 40px;
                            color: #242629;
                            padding: 0;
                        }

                        .select {
                            width: 79px;
                            height: 40px;
                            border: 1px solid #CED0D6;
                            border-radius: 4px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 40px;
                            background: #2C6ECB;
                            color: #FFFFFF;
                            padding: 0;
                        }

                        .noselect {
                            width: 79px;
                            height: 40px;
                            border: 1px solid #CED0D6;
                            border-radius: 4px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 40px;
                            background: #BDC1CC;
                            color: #FFFFFF;
                            padding: 0;
                        }
                    }
                }
            }

            .pa {
                cursor: pointer;
                width: 200px;
                font-size: 16px;
                color: #2C6ECB;
                font-weight: 500;
            }

            >div {
                font-weight: 600;
                font-size: 18px;
                padding-bottom: 28px;

                >span {
                    margin-bottom: 12px;
                }

                >p {
                    display: block;
                    margin: 0;
                    height: 32px;
                    font-weight: 500;
                    font-size: 14px;
                    color: #2C6ECB;
                    display: flex;
                    align-items: center;
                    margin-bottom: 28px;

                    i {
                        width: 16px;
                        height: 16px;
                        display: flex;
                        align-items: center;
                        margin-right: 7px;
                    }

                    i::before {
                        font-size: 16px;
                    }
                }

                input {
                    min-height: 40px;
                    border-radius: 4px;
                }

                .radio {
                    label {
                        >span {
                            height: 18px;
                            padding: 0;
                            margin-right: 9px;
                            font-weight: 400;
                            font-size: 14px;
                            color: #202223;

                            input {
                                width: 86px;
                            }

                            input:checked+span {
                                border: 2px solid #2C6ECB;
                            }

                            input:checked+span::after {
                                background: #2C6ECB;
                            }

                            span {
                                width: 18px;
                                height: 18px;
                                border: 2px solid #8C9196;
                                border-radius: 100%;
                            }
                        }
                    }

                    input {
                        width: 121px;
                        height: 40px;
                        background: #F4F5F7;
                        border: 1px solid #C9CCCF;
                        border-radius: 4px;
                        margin-right: 8px;
                    }

                    span {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                    }
                }

                .textarea {
                    position: relative;
                    border: 1px solid #C9CCCF;
                    border-radius: 4px;
                    padding-bottom: 20px;
                    box-shadow: none;
                    margin-top: 12px;
                }

                .textarea::after {
                    position: absolute;
                    font-weight: 400;
                    font-size: 12px;
                    right: 10px;
                    bottom: 0;
                }

                .textareatwo {
                    position: relative;
                    border: 1px solid #C9CCCF;
                    border-radius: 4px;
                    padding-bottom: 20px;
                    box-shadow: none;
                    margin-top: 40px;
                    margin-bottom: 17px;
                }

                .textareathree {
                    height: 154px;
                    position: relative;
                    border: 1px solid #C9CCCF;
                    border-radius: 4px;
                    padding-bottom: 20px;
                    box-shadow: none;
                    margin-top: 12px;
                }

                .textareathree::after {
                    display: none;

                }

                .textareatwo::after {
                    position: absolute;
                    font-weight: 400;
                    font-size: 12px;
                    right: 10px;
                    bottom: 0;
                }

                textarea {
                    min-height: 68px;
                    border-radius: 4px;
                    border: none;
                    resize: none;
                    box-shadow: none;
                }


            }

            input {
                width: 765px;
            }

            .inputbottom {
                font-weight: 400;
                font-size: 12px;
                color: #8C9196;
                padding-top: 8px;
                border: none;
            }

            .threeinput {
                padding-bottom: 0;
                margin-top: 12px;

                .lasttext {
                    font-weight: 400;
                    font-size: 14px;
                    color: #242629;
                }

                .bordertop {
                    width: 100%;
                    height: 5px;
                    border-top: 1px solid #C9CCCF;
                }

                >span {
                    display: block;

                    button {
                        width: 24px;
                        height: 24px;
                        background: #F6F6F7;
                        border: 1px solid #C9CCCF;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        i {
                            width: 24px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                >p {
                    width: 370px;
                    cursor: pointer;
                }

                >p:hover {
                    color: #1F5199;
                }

                .input {
                    display: flex;
                    justify-content: left !important;
                    margin: 12px 0;

                    >div {
                        width: 370px;
                        height: 70px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin-right: 24px;
                        margin-left: 0;

                        p {
                            height: 22px;
                            font-weight: 400;
                            font-size: 14px;
                            color: #242629;
                            margin: 0;
                            line-height: 22px;

                            button {
                                width: 24px;
                                height: 24px;
                                background-color: #fff;
                                border: 1px solid #C9CCCF;
                                border-radius: 4px;
                                cursor: pointer;

                                i::before {
                                    font-size: 20px;
                                    color: #6D7175;
                                }
                            }

                            button:hover {
                                background: #F6F6F7;
                            }

                            button:active {
                                background: #F1F2F3;
                            }
                        }

                        input {
                            margin-top: 8px;
                            width: 370px;
                        }
                    }

                    .period {
                        width: 766px;

                        p {
                            width: 766px;
                            margin-bottom: 8px;

                        }

                        >div {
                            height: 40px;
                            width: 370px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            border: 1px solid #C9CCCF;
                            border-radius: 4px;
                            margin: 0;

                            >p {
                                font-weight: 400;
                                font-size: 14px;
                                color: #6D7175;
                                margin-right: 20px;
                                margin-bottom: 0;
                            }

                            .date {
                                width: 156px;
                                padding: 0;
                                height: 40px;

                                >div {
                                    width: 156px;
                                    height: 40px;

                                    input {
                                        padding-left: 12px;
                                        width: 144px;
                                        margin: 0;
                                    }
                                }

                                span {
                                    display: none;
                                }
                            }
                        }

                        .inputbottom {
                            border: none;
                            color: #F53333;
                        }

                        .erro {
                            border: 1px solid #FD5749;
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
    }

}

.after {
    width: 100%;
    height: 3.472vw;
}
}