.box{
    width: 1224px;
    margin: auto;
    >p{
        font-weight: 700;
        font-size: 34px;
        line-height: 40px;
        margin-top: 48px;
        margin-bottom: 32px;
    }
    .body{
        width: 1224px;
        background: #FFFFFF;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 0 32px;
        >div{
            border-top: 1px solid #D9D9D9;
            padding: 32px 0;
            >p{
                font-weight: 700;
                font-size: 24px;
                color: #000000;
                margin-bottom: 16px;
                
            }
            >span{
                display: block;
                width: 776px;
                font-weight: 400;
                font-size: 16px;
                color: #000000;
            }
            >button{
                width: 216px;
                height: 40px;
                background: #2C6ECB;
                border-radius: 4px;
                border: none;
                cursor: pointer;
                color: #fff;
                font-weight: 500;
                font-size: 14px;
                margin-top: 16px;
            }
            >ul{
                width: 704px;
                height: 72px;
                margin-top: 16px;
                margin-bottom: 0;
                li{
                    text-align: left;
                    font-weight: 400;
                    font-size: 16px;
                    color: #202223;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                }
                li::before{
                    content: "·";
                    font-size: 35px;
                    margin-right: 10px;
                }
            }
        }
        .last{
            >div{
                display: flex;
                justify-content: space-between;
                margin-bottom: 16px;
                >div{
                    width: 266px;
                    height: 144px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    img{
                        width: 96px;
                        height: 96px;
                    }
                    p{
                        height: 24px;
                        line-height: 24px;
                        font-weight: 400;
                        font-size: 16px;
                        color: #202223;
                        margin-bottom: 24px;
                    }
                }
            }
        }
        .first{
            border: none;
        }
        
    }
    
}
.after{
    width: 100%;
    height: 50px;
}
.foot {
    display: none;
}
.after{
    width: 100%;
    height: 100px;
    background: transparent;
}
@media not screen and (min-width:300px) and (max-width: 720px){
    .box {
        width: 1224px;
        
    }
}
@media only screen and (min-width:300px) and (max-width: 720px) {
    html {
        font-size: 12px;
    }
    .after{
        display: none;
    }
    .box {
        width: 100vw;
        padding: 7vw 6vw;
        >p{
            font-weight: 700;
            width: 60vw;
            font-size: 7vw;
            color: #202223;
            margin: 0;
            margin-bottom: 4.44vw;
        }
        .body {
            width: 88vw;
            min-height: 100vh;
            margin-top: 0;
            border-radius: 0;
            padding: 0 4.44vw;
            border-radius: 2.22vw;
            >div {
                padding: 0;
                padding-bottom: 4.44vw;
                >p {
                    font-weight: 700;
                    font-size: 5vw;
                    width: 80vw;
                    color: #000000;
                    margin-bottom: 2vw;
                    line-height: 7vw;
                    padding-top: 4.444vw;
                }
        
                >span {
                    display: block;
                    width: 80vw;
                    font-weight: 400;
                    font-size: 3.9vw;
                    color: #000000;
                }
        
                >button {
                    width: 60vw;
                    height: 11.111vw;
                    background: #2C6ECB;
                    border-radius: 4px;
                    border: none;
                    cursor: pointer;
                    color: #fff;
                    font-weight: 500;
                    font-size: 3.889vw;
                    margin-top: 4.44vw;
                    margin-bottom: 4.444vw;
                }
        
                >ul {
                    width: 80vw;
                    height: 30.556vw;
                    margin-top: 2.222vw;
                    margin-bottom: 0;
        
                    li {
                        width: 75vw;
                        text-align: left;
                        font-weight: 400;
                        font-size: 3.889vw;
                        color: #202223;
                        line-height: 6.111vw;
                        display: flex;
                        align-items:flex-start;
                    }
        
                    li::before {
                        content: "·";
                        font-size: 9.722vw;
                        margin-right: 2.778vw;
                    }
                }
            }
        
            .last {
                >p {
                    text-align: center;
                    margin-bottom: 4.44vw;
                }
                >button{
                    margin-left: 10vw;
                    margin-top: 4.444vw;
                }
                >div {
                    width: 80vw;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin: 0;
                    >div {
                        width: 35.556vw;
                        height: 30vw;
                        display: flex;
                        flex-direction:column-reverse;
                        align-items: center;
                        justify-content: center;
                        img{
                            width: 17.778vw;
                            height: 17.778vw;
                        }
                        p {
                            height: 10vw;
                            font-size: 3.333vw;
                            font-weight: 400;
                            line-height: 5vw;
                            color: #202223;
                            text-align: center;
                        }
                    }
                }
            }
            .first{
                border: none;
                padding: 0;
                p{
                    padding-top: 4.44vw;
                }
            }
        }
    }
    .foot {
        display: block;
        width: 100vw;
        height: 100%;
        background-color: #fff;
        padding: 8.889vw 5.556vw;
        >div {
            width: 88.889vw;
        }

        .top {
            display: none;
        }

        .mobiletop {
            height: 100%;
            border-bottom: 1px solid #D9D9D9;
            margin-bottom: 6.667vw;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 2.222vw;
            margin-top: -6.667vw;

            >div {
                width: 88.889vw;
                height: 6.111vw;
                display: flex;
                justify-content: space-between;
                margin-top: 6.667vw;
                font-weight: 700;
                font-size: 3.889vw;
                color: #202223;
                margin-bottom: 2.222vw;

                i {
                    width: 5.556vw;
                    height: 5.556vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                i::before {
                    font-weight: 500;
                    font-size: 5.556vw;
                    color: #5C5F62;
                }
            }

            .topexpanse {
                margin: 0;
                height: 100%;
                display: flex;
                flex-direction: column;

                span {
                    font-weight: 400;
                    font-size: 3.889vw;
                    color: #6D7175;
                    line-height: 8.333vw;
                }
            }

        }

        .bottom {
            height: 102.222vw;
            flex-direction: column;
            margin-right: 0;
            margin-bottom: 3.333vw;
            >div {
                width: 88.889vw;
                height: 13.333vw;
                margin-bottom: 4.444vw;
                p {
                    height: 6.111vw;
                    line-height: normal;
                    margin: 0;
                    font-weight: 400;
                    font-size: 3.889vw;
                }
                span {
                    font-size: 3.889vw;
                }
            }
        }

        >span {
            width: 88.889vw;
            font-size: 3.333vw;
        }
    }
}