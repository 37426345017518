body{
    // height: 100vh;
    // background: #FFFFFF;
    .iswarning {
            div {
                background-color: #202123;
                color: white;
                border-radius: 8px;
    
                >span {
                    font-size: 16px;
                    font-weight: 400;
    
                    svg {
                        display: none;
                    }
                }
            }
        }
    .box {
        width: 100vw;
        min-height: 91.4vh;
        background: #fff;
        border-top: 1px solid #ECECEC;
        padding: 1.111vw 20.002px;
        display: flex;
        flex-direction: column;
        p{
            height: 6.111vw;
            font-weight: 700;
            font-size: 3.889vw;
            line-height: 6.111vw;
            margin-bottom: 4.444vw;
            margin-top: 5.556vw;
        }
        span{
            margin-bottom: 3.333vw;
            cursor: pointer;
        }
        >div{
            margin-top: 3.333vw;
            border-top: 1px solid #D9D9D9;
            padding-top: 6.667vw;
            color: #6D7175;
            .engorkor {
                padding: 2.5vw 3.333vw;
                width: 23.889vw;
                height: 11.111vw;
                display: flex;
                align-items: center;
                padding-left: 3.333vw;
                border-radius: 1.111vw;
                cursor: pointer;
                i{
                    margin-left: 8px;
                }
            }
        }
    }
    .modal {
        border-radius: 8px;
        margin: 0;
        .last{
            font-weight: 400;
            font-size: 14px;
            margin: 24px 0 8px 0;
        }
        
        >div {
            width: 100vw;
            border: none;
            font-weight: 700;
            font-size: 16px;
            
            >div {
                border: none;
                >span {
                    display: flex;             
                    width: 86.667vw;
                    height: 50px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #6D7175;
                    word-break: break-all;
                    margin-top: -24px;
                }
                >div{
                    font-weight: 700;
                    font-size: 16px;
                    color: #242629;
                    display: flex;
                    flex-direction: column;
                }
            }
            p{
                display: inline-block;
                height: 22px;
                width: 244px;
                font-weight: 400;
                font-size: 14px;
                color: #242629;
                margin: 0;
                line-height: 22px;
                margin-right: 24px;
                margin-bottom: 8px;
            }
            p::after{
                content: "*";
                color: red;
                margin-left: 2px;
            }
            input{
                width: 312px;
                height: 38px;
                border: 1px solid #C9CCCF;
                border-radius: 4px;
                margin-right: 24px;
                margin-bottom: 21px;
                
            }
            .inquirytype{
                width: 312px;
                height: 40px;
                border: 1px solid #CED0D6;
                border-radius: 4px;
                font-weight: 400;
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #242629;
                margin-bottom: 21px;
            }
            .cancel {
                width: 79px;
                height: 40px;
                border: 1px solid #CED0D6;
                border-radius: 4px;
                font-weight: 500;
                font-size: 14px;
                line-height: 40px;
                color: #242629;
                padding: 0;
                margin-right: 16px;
            }

            .select {
                width: 217px;
                height: 40px;
                border: 1px solid #CED0D6;
                border-radius: 4px;
                font-weight: 500;
                font-size: 14px;
                line-height: 40px;
                background: #2C6ECB;
                color: #FFFFFF;
                padding: 0;
            }
            .dropdown {
                width: 312px;
                height: 40px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 21px;
                color: #6D7175;
                a {
                    width: 144px;
                    height: 40px;
                    display: block;
                    border: 1px solid #CED0D6;
                    border-radius: 4px;
                    padding-left: 12px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    >div {
                        font-weight: 400;
                        font-size: 14px;
                        color: #9B9DA9;
                    }

                    .divclick {
                        font-weight: 400;
                        font-size: 14px;
                        color: #242629;
                    }

                    i::before {
                        font-size: 16px;
                        color: #5A607D;
                    }
                }

                i::before {
                    font-size: 16px;
                    color: #9BA3BE;
                    font-weight: lighter;
                }

            }
            .url {
                display: flex;
                flex-direction: column;

                div {
                    width: 300px;
                    height: 40px;
                    margin-bottom: 8px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    input {
                        width: 100%;
                        margin: 0;
                    }

                    .input {
                        width: 480px;
                        margin: 0;
                    }

                    button {
                        width: 24px;
                        height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #FFFFFF;
                        border-radius: 4px;
                        border: 1px solid #C9CCCF;
                        cursor: pointer;

                        i {
                            width: 24px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        i::before {
                            font-size: 20px;
                        }
                    }
                }

                span {
                    width: 119px;
                    height: 32px;
                    font-weight: 500;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    color: #2C6ECB;
                    cursor: pointer;

                    i {
                        margin-right: 4px;
                        width: 16px;
                        height: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    i::before {
                        font-size: 16px;
                        color: #2C6ECB;
                    }
                }
            }
            .textarea {
                position: relative;
                border: 1px solid #C9CCCF;
                border-radius: 4px;
                padding-bottom: 20px;
                box-shadow: none;
                textarea {
                    min-height: 66px;
                    border-radius: 4px;
                    border: none;
                    resize: none;
                    box-shadow: none;
                }
            }
            .textarea::after {
                position: absolute;
                font-weight: 400;
                font-size: 12px;
                right: 10px;
                bottom: 0;
                color: #6D7175;
            }

        }
    }
}