.beensent{
    width: 600px;
    height: 360px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin: auto;
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        width: 128px;
        height: 128px;
        margin-bottom: 24px;
    }
    .div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                color: #202223;
                margin: 0;
                text-align: center;
                margin-bottom: 8px;
            }
        
            span {
                width: 504px;
                text-align: center;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #202223;
            }
    }
    .divno{
        height: 0;
        overflow: hidden;
    }
}
@media only screen and (min-width: 300px) and (max-width: 720px) {
    .beensent{
        margin-top: 6.667vw;
        width: 88.889vw;
        height: 88.889vw;
        img{
            width: 23.333vw;
            height: 23.333vw;
        }
        .div{
            width: 88.889vw;
            margin-bottom: 5.556vw;
            p{
                width: 88.889vw;
                font-size: 5vw;
                text-align: center;
                margin-bottom: 3.333vw;
            }
            span{
                font-size: 3.889vw;
                width: 77.778vw;
                line-height: 6.111vw;
            }
        }
    }
}
